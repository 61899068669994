import React, { useEffect, useState, useRef } from "react";
import { initializeStore } from "/components/store/store";
import { parseCookies } from "nookies";
import { useRouter } from "next/router";
import { getWebsiteID } from "/lib/common-functions";
import { useCookies } from "react-cookie";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { EventCommonObj } from "/lib/services/helpers/eventCommonObject";
import { getReduxSession } from "/lib/sessionHandler";
import { setVideoStatus } from "/lib/services/helpers/setVideoStatus";
import { useInView } from "react-intersection-observer";
import { useIsMobile } from "/lib/hooks/useIsMobile";
import { AddToBagEvent, RemoveFromWishlist, VideoIconClick, event } from "/lib/ga";

function YouTubeGetID(url) {
    var ID = "";
    url = url
        .replace(/(>|<)/gi, "")
        .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_\-]/i);
        ID = ID[0];
    } else {
        ID = url;
    }
    return ID;
}

function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function instockVariantPrice(variants) {
    let variantPrice = 0;
    if (variants && variants.length) {
        for (let i = 0; i < variants.length; i++) {
            if ((variants[i].product.stock_status == 'IN_STOCK')) {
                variantPrice = variants[i].product.price_range.minimum_price.regular_price.value;
                break;
            }
        }
    }
    return variantPrice;
}

export const useListItemSale = (props) => {
    
    const {
        storeid,
        name,
        image,
        description,
        slug,
        id,
        type,
        sku,
        url_key,
        price,
        mprice,
        conf,
        variants,
        session,
        media_gallery,
        pwa_oneliner,
        index,
        prodVisibilityArr = [],
        setProdVisibilityArr = () => { },
        ItemCategory3,
        groupedProductItems = []
    } = props;

    const useStyles = useIsMobile()
    ? makeStyles((theme) => ({
        paper: {
            position: "absolute",
            width: "90%",
            background: "#fff",
        },
    }))
    : makeStyles((theme) => ({
        paper: {
            position: "absolute",
            width: "60%",
            background: "#fff",
        },
    }));

    const sessionData = getReduxSession(true);
    const [showLoader, setShowLoader] = useState(false);
    const isMobileDevice = useIsMobile();
    const cookies = parseCookies(null, "fromClient");
    const reduxStore = initializeStore();
    const [cookie, setCookie] = useCookies(["store"]);
    const [utmsource, setUtmsource] = useCookies(["utm_source"]);
    let website_id = getWebsiteID(cookie);
    const [modalStyle] = React.useState(getModalStyle);
    const coreclasses = useStyles();
    const [cartMessage, setCartMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const [open, setOpen] = useState(false)
    const config = useSelector((state) => state.configdata);
    const { dispatch } = reduxStore;
    const router = useRouter();
    const { ref: ref, inView: listItemInView } = useInView({
        threshold: 0.25,
        triggerOnce: true,
    });
    const eventCommonObj = EventCommonObj(sessionData, cookie)
    let websiteurl = (storeid == 1) ? "" : "/international/"
    const conf0 = conf && conf[0] ? conf[0] : [];
    const homeOrder = props.home_order && props.home_order == true ? true : false;
    const categoryUrlSuffix = ".html";
    const [showMe, setShowMe] = useState(false);
    const [mediaImage, setMediaImage] = useState(image);
    const [ButtonText, setButtonText] = useState("Add to Bag");
    const [bSnackbarVisible, setTriggerShowSnackbar] = useState(false);
    const [modalopen, setModalOpen] = useState(false);
    const [variantLabel, setVariantLabel] = useState(null);
    const [cPrice, setCPrice] = useState(price);
    const [mPrice, setMPrice] = useState(mprice);
    const [vPrice, setVPrice] = useState(instockVariantPrice(variants));
    const [groupedProductPrice, setGroupedProductPrice] = useState(price);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [notifymessage, setNotifyMessage] = useState();
    const [notifybuttonmessage, setNotifyButtonMessage] = useState("Notify");
    const currency = storeid == 2 ? "$" : "₹";
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const exploreLink = `/${slug}`;

    const strippedString = `${description.replace(/(<([^>]+)>)/gi, "").substr(0, 50)}...`;

    const strippedTitle = `${name.length > 60 ? `${name.substr(0, 60).toLowerCase()}..` : name.toLowerCase()}`;

    const strippedContent = pwa_oneliner
        ? pwa_oneliner
        : description.length > 50
            ? `${description.substr(0, 50).toLowerCase().replace(/<[^>]+>/g, "")}..`
            : description.toLowerCase().replace(/<[^>]+>/g, "");

    let firstvideo = (media_gallery) ? media_gallery.filter(
        (item) => item.__typename === "ProductVideo"
    ) : [];

    firstvideo = firstvideo.length > 0 ? firstvideo[0] : null;

    const ConditionalWrapper = ({ condition, children }) =>
        condition ? children : "";

    const setPrice = async (event) => {
        const selectedsku = event.target.value;
        for (const [index, variant] of variants.entries()) {
            if (variant.product.sku == selectedsku) {
                setCPrice(variant.product.price.regularPrice.amount.value);
                setMPrice(variant.product.price_range.minimum_price.regular_price.value)
                setVPrice(variant.product.price_range.minimum_price.regular_price.value);
                setVariantLabel(variant?.attributes[0]?.label);
                setSelectedIndex(index);
                setMediaImage(variant.product.small_image.url)
            }
        }
    };

    const toggleDrawer = (anchor, open) => (eventName) => {
        if (
            eventName &&
            eventName.type === "keydown" &&
            (eventName.key === "Tab" || eventName.key === "Shift")
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
        if (open) {
            event({
                action: "notify-click",
                params: {
                    "event_label": 'notify-click',
                    "event_category": 'notify',
                    "value": 'notify-click',
                }
            })
        }
    };

    function isDefaultSelected(index) {
        if (selectedIndex == -1) {
            const value =
                variants?.[0]?.product?.stock_status == "OUT_OF_STOCK" || (variants[0]?.product?.salableqty?.label <= 0)
                    ? variants[index].product.stock_status == "IN_STOCK"
                        ? true
                        : false
                    : index == 0
                        ? true
                        : false;
            return value;
        } else if (index == selectedIndex) {
            return true;
        } else return false;
    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        //setOpen(false);
        setTriggerShowSnackbar(false);
    };

    function toggle() {
        setShowMe(!showMe);
    };

    const handleOpen = (e) => {
        e.preventDefault();
        VideoIconClick(name, url_key, '', eventCommonObj);
        setVideoStatus(id);
        setModalOpen(true);
    };

    const handleMClose = (e) => {
        e.preventDefault();
        setModalOpen(false);
    };

    const addtobag = async (event) => {
        event.preventDefault();
        setShowLoader(true)
        setButtonText("Please Wait");
        
        try {
            const res = await fetch(`${type == 'grouped' ? '/api/addtobaggrouped' : '/api/addtobag'}`, {
                body: JSON.stringify(type == 'grouped' ? [`{
                    sku: "${event.target.sku.value || sku}",
                    quantity: "${event.target.qty.value}"
                  }`] : {
                    parentsku:
                        event.target.parentsku && event.target.parentsku.value
                            ? event.target.parentsku.value
                            : "",
                    sku: event.target.sku.value,
                    qty: event.target.qty.value,
                    cookie: cookies,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
            });
            AddToBagEvent(props, cPrice, mPrice, session, variantLabel, event.target.sku.value, event.target.qty.value, website_id, eventCommonObj, ItemCategory3)
            
            const bagdata = await res.json();
            if (bagdata?.message) {
                setOpen(true);
                setSuccessMessage(false)
                setCartMessage(bagdata.message);
                setTriggerShowSnackbar(true);
                setShowLoader(false)
                setButtonText("Add to Bag");
            } else {
                setButtonText("Added");
                setShowLoader(false)
                setOpen(true);
                setSuccessMessage(true)
                let cartLink = (website_id == 1) ? "/checkout/cart" : "/international/checkout/cart"
                let cartMsg = "Product added to Bag! <a href=" + cartLink + ">VIEW BAG</a>"
                setCartMessage(cartMsg);
                setTriggerShowSnackbar(true);
                let Qty = (bagdata.total) ? (bagdata.total) : 1
                dispatch(
                    {
                        type: 'INCREMENT',
                        count: Qty,
                    })
                dispatch(
                    {
                        type: 'MINICARTTOTAL',
                        total_price: bagdata.grand_total,
                    })
                setButtonText("Add to Bag");
                if (utmsource['utm_source'] == 'Colombia') {
                    function setAttributes(element, attributes) {
                        Object.keys(attributes).forEach(attr => {
                            element.setAttribute(attr, attributes[attr]);
                        });
                    }
                    const attributes = {
                        id: 'timesimg',
                        height: '1',
                        width: '1',
                        style: 'display:none',
                    };
                    var img = document.createElement("img");
                    setAttributes(img, attributes);
                    img.src = "https://ade.clmbtech.com/cde/eventTracking.htm?pixelId=14381&_w=1&_t=1";
                    var element = document.querySelector('head');
                    element.appendChild(img);
                    img.remove();
                }
            }
        } catch (error) {
            console.log('error', error);
            setShowLoader(false)
            setButtonText("Add to Bag");
        }
    };

    const removeWishlistItem = async (event) => {
        event.preventDefault();
        setShowLoader(true)
        setButtonText("Please Wait");
        const productcategory = props?.variants?.[0]?.product?.primary_category || null
        RemoveFromWishlist(productcategory, name, eventCommonObj)
       
        try {
            const res = await fetch("/api/remove-wishlist-item", {
                body: JSON.stringify({
                    wishlistId: event.target.wishlistId.value,
                    wishlistItemsIds: [event.target.wishlistItemsIds.value],
                    accessToken: session.accessToken,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
            });
            const data = await res.json();
            setShowLoader(false)
            if (data && data.id) {
                router.replace(router.asPath);
            }
        } catch (error) {
            console.log('error', error);
            setShowLoader(false);
        }
    };

    useEffect(() => {
        if(type == 'grouped' && groupedProductItems?.length > 0){
            const sumProduct = groupedProductItems?.reduce((prev, item) => {
              return prev + item.product.price.regularPrice.amount.value;
            },0)
  
            setGroupedProductPrice(sumProduct);
          }
    },[url_key])

    useEffect(() => {
        prodVisibilityArr[index] = listItemInView;
        setProdVisibilityArr([...prodVisibilityArr])
    }, [listItemInView]);

    return {
        firstvideo,
        modalStyle,
        coreclasses,
        ref,
        showLoader,
        router,
        variantLabel,
        eventCommonObj,
        mPrice,
        cPrice,
        homeOrder,
        categoryUrlSuffix,
        websiteurl,
        mediaImage,
        strippedTitle,
        isMobileDevice,
        strippedContent,
        currency,
        vPrice,
        groupedProductPrice,
        ConditionalWrapper,
        config,
        addtobag,
        ButtonText,
        showMe,
        toggle,
        bSnackbarVisible,
        conf0,
        isDefaultSelected,
        setPrice,
        handleMClose,
        YouTubeGetID,
        handleOpen,
        modalopen,
        removeWishlistItem,
        website_id,
        toggleDrawer,
        handleClose,
        cartMessage,
        successMessage,
            }
}